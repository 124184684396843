var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"row mt-3 mb-3"},[_c('div',{staticClass:"col-sm-6 form-inline"},[_c('filter-bar',{model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right"},[(_vm.$can('CREATE', 'PRODUCTCATEGORY'))?_c('add-label-button',{attrs:{"title":_vm.i18nNewProductCategory},on:{"onClick":_vm.openModalToCreate}}):_vm._e(),(_vm.$can('CREATE', 'IMPORTJOB'))?_c('import-button',{on:{"onClick":_vm.showImportModal}}):_vm._e(),(_vm.$can('CREATE', 'EXPORTJOB'))?_c('export-button',{attrs:{"type":"product_category","create-parameters":_vm.createAdditionalParameters}}):_vm._e()],1)])]),_c('div',{staticClass:"card card-pdv"},[_c('PageHeader',{attrs:{"title":_vm.i18nProductCategory(2)}}),_c('div',{staticClass:"card-body"},[_c('api-mode-vuetable',{ref:"vuetable",attrs:{"fetch-data":_vm.fetch,"fields":_vm.fields,"per-page":_vm.perPage,"display-selection-records-per-page":true,"additional-parameters":_vm.additionalParameters,"sort-order":_vm.sortOrder},scopedSlots:_vm._u([{key:"subcategories",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.children
                                    ? props.rowData.children.length
                                    : 0},on:{"onClick":function($event){return _vm.openModalSubcategories(props.rowData)}}})]}},{key:"products",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.products.length},on:{"onClick":function($event){return _vm.openModalProducts(props.rowData)}}})]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"table-button-container"},[_c('more-table-button',{on:{"onClick":function($event){return _vm.openModalToEdit(props.rowData)}}})],1)]}}])})],1)],1)],1),_c('productCategoriesModal',{ref:"productCategoriesModal",on:{"refresh":_vm.reloadTable}}),_c('grid-form-modal',{ref:"gridSubcategoriesModal",attrs:{"modal":_vm.modal,"items":_vm.modal.children,"fields":_vm.fieldsSubcategorieModal,"title":_vm.getI18nModified({
                prefix: 'PRODUCT_CATEGORIES',
                suffix: 'TITLES.parent_category',
                modifier: 2,
            })}}),_c('grid-form-modal',{ref:"gridProductsModal",attrs:{"modal":_vm.modal,"items":_vm.modal.products,"fields":_vm.fieldsProductModal,"title":_vm.getI18nModified({
                prefix: 'PRODUCTS',
                suffix: 'TITLES.product',
                modifier: 2,
            })}}),_c('import-modal',{ref:"importModal",attrs:{"csv-type":'product_category',"title":_vm.getI18nModified({
                prefix: 'IMPORT_JOBS',
                suffix: 'TITLES.product_category',
                modifier: 2,
            })}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }