<script>
import ProductCategoriesService from '@src/services/ProductCategoriesService.js'
import Layout from '@layouts/main.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import productCategoriesModal from './productCategoriesModal'
import GridFormModal from '@src/components/GridFormModal.vue'
import FilterBar from '@src/components/FilterBar'
import swalFeedback from '@src/mixins/swalFeedback'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nKey = 'PRODUCT_CATEGORIES'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        productCategoriesModal,
        GridFormModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        CountTableButton,
        MoreTableButton,
    },
    mixins: [ProductCategoriesService, swalFeedback],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modal: {
                id: null,
                name: '',
                parent_id: null,
                old_parent_id: null,
                parent_name: '',
                children: [],
                products: [],
            },
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'subcategories',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.parent_category',
                        modifier: 2,
                    }),
                },
                {
                    name: 'products',
                    title: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            fieldsSubcategorieModal: [
                {
                    key: 'name',
                    label: this.getI18n(
                        'PRODUCT_CATEGORIES',
                        'TITLES.parent_category'
                    ),
                    sortable: true,
                },
            ],
            fieldsProductModal: [
                {
                    key: 'name',
                    label: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortable: true,
                },
            ],
            filterText: '',
            additionalParameters: {
                with: ['children', 'products'],
            },
        }
    },

    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.product_category',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewProductCategory() {
            return this.getI18n(i18nKey, 'BUTTONS.new_product_category')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return ProductCategoriesService.fetchVuetable(url, params)
        },
        i18nProductCategory(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.product_category',
                modifier: modifier,
            })
        },
        createAdditionalParameters() {
            const additionalParameters = { search: '' }
            if (this.filterText && this.filterText.length > 0) {
                additionalParameters.search = `name:${this.filterText}`
            }
            return additionalParameters
        },
        cleanModal() {
            this.$nextTick(() => this.$refs.productCategoriesModal.cleanModal())
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.productCategoriesModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        openModalSubcategories(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridSubcategoriesModal.showModal())
        },
        openModalProducts(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridProductsModal.showModal())
        },
        replaceModalData(data) {
            Object.assign(this.modal, data)

            Object.assign(this.modal, {
                children: [],
                products: [],
            })

            Object.assign(this.modal.children, data.children)
            Object.assign(this.modal.products, data.products)
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'PRODUCTCATEGORY')"
                            :title="i18nNewProductCategory"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="product_category"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nProductCategory(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="subcategories" slot-scope="props">
                            <count-table-button
                                :value="
                                    props.rowData.children
                                        ? props.rowData.children.length
                                        : 0
                                "
                                @onClick="openModalSubcategories(props.rowData)"
                            />
                        </template>
                        <template slot="products" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.products.length"
                                @onClick="openModalProducts(props.rowData)"
                            />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button
                                    @onClick="openModalToEdit(props.rowData)"
                                />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>

        <productCategoriesModal
            ref="productCategoriesModal"
            @refresh="reloadTable"
        />

        <grid-form-modal
            ref="gridSubcategoriesModal"
            :modal="modal"
            :items="modal.children"
            :fields="fieldsSubcategorieModal"
            :title="
                getI18nModified({
                    prefix: 'PRODUCT_CATEGORIES',
                    suffix: 'TITLES.parent_category',
                    modifier: 2,
                })
            "
        />

        <grid-form-modal
            ref="gridProductsModal"
            :modal="modal"
            :items="modal.products"
            :fields="fieldsProductModal"
            :title="
                getI18nModified({
                    prefix: 'PRODUCTS',
                    suffix: 'TITLES.product',
                    modifier: 2,
                })
            "
        />

        <import-modal
            ref="importModal"
            :csv-type="'product_category'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.product_category',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
